import React from "react"
import { graphql, StaticQuery } from "gatsby"
import "./EFYBadge.scss"

export default props => (
  /**
   * Gets all the data for the badge from AgilityCMS
   */

  <StaticQuery
    query={graphql`
      {
        agilityEfyBadge {
          id
          customFields {
            eFYBadge {
              url
              filesize
              pixelHeight
              pixelWidth
              label
            }
          }
        }
      }
    `}
    render={queryData => {
      const viewModel = {
        customFields: queryData.agilityEfyBadge.customFields,
      }
      return <EFYBadge {...viewModel} />
    }}
  />
)

/**
 * Component to render the badge
 * @function Badge
 */
const EFYBadge = props => {
  const customFields = props.customFields

  return (
    <section className="badge-careers">
      <div className="container">
        <div className="row">
          <div className="col-12 d-flex justify-content-center">
            <img className="efy-badge"
              src={customFields.eFYBadge ? customFields.eFYBadge.url : ""}
              alt={customFields.eFYBadge.label}
            />
          </div>
        </div>
      </div>
    </section>
  )
}
